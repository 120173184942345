@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "@fortawesome/fontawesome-free/css/all.css";


body {
  margin: 0;
  padding: 0;
  background: #0e1217;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body,
html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "anek-bangla";
  src: url("./fonts/AnekBangla_SemiExpanded-SemiBold.ttf") format("truetype");
  /* font-weight: normal;
  font-style: normal; */
}

:root {
  --text-color: #ffffff;
  --black: #000000;
  --background: #000000;
  --black-text-color: #131313;
  --task-container-color: #1e2126;
  --task-image-container-color: #1e2126;
  --task-description-color: #a1a1a1;

  --invite-friend-container-color: #2b2b2b;
  --background2: #1c1c1c;
  --background3: #303038;
  --background-secondary-color: #171717;
  --red-color: #db4427;
  --green-color: #17c273;
  --border-color: #414141;
  --theme-secondary-color: #878787;
  --theme-secondary2-color: #1f2026;
  --accent-color: #c3fc49;
  --accent-light-color: #dffca2;
  --text-secondary-color: #8b8b8b;
  --white2: #e1e1e1;
  --pnl-green: #5fff5b;
  --pnl-red: #ff3b30;
  --grey: #676c70;

  --secondary-text-color: hsl(0 0% 51%);
  --background-secondary-color: #ffffff14;
  --theme-secondary2-color: #202127;
  --theme-opposite-color: #ffffff;
  --theme-secondary-color: hsl(207, 4%, 42%);
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for webkit browsers (Chrome, Safari, etc.) */
}

.row-alignment,
.row-column-alignment {
  display: flex;
  flex-direction: row;
}

.column-alignment {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.fill {
  flex: 1 1 0;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}
.align-self-start {
  align-self: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-right {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

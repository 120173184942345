/* General dark theme */
.dark-theme {
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

/* .dark-theme:hover {
  transform: scale(1.05);
} */

.card-header {
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
}

.card {
  flex: 0 0 auto;
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.topmate-card-container {
  flex: 0 0 auto;
  scroll-snap-align: center; /* Aligns the card in the center while scrolling */
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.rating {
  background-color: #333;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.rating svg {
  color: gold;
  margin-right: 5px;
}

.card-content {
  margin-top: 10px;
}

.topmate-card-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 4px;
}

.text h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.topmate-card-subtitle {
  font-size: 1rem;
  color: #aaa;
  margin: 6px;
}

.text p {
  font-size: 1rem;
  color: #aaa;
}

.details {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.icon-text {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.icon-text svg {
  margin-right: 8px;
  color: #aaa;
}

.pricing {
  margin-top: 8px;
  padding: 8px 16px;
  background-color: #333;
  border-radius: 18px;
}

.original-price {
  text-decoration: line-through;
  color: #fff;
  margin-right: 10px;
  font-size: 1rem;
}

.current-price {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.arrow {
  font-size: 1rem;
  color: white;
  margin: 0 4px;
}

.event-card {
  /* max-width: 300px; */
  background-color: #1e1e1e;
  color: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}


.event-card-header {
  position: relative;
  padding: 0;
}
.event-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: white;
  color: grey;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}
.event-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.event-date {
  position: absolute;
  bottom: -22px;
  left: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
}
.event-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.event-detail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
}
.event-detail i {
  margin-right: 8px;
}
.event-price {
  margin-top: 12px;
  padding: 8px;
  background-color: #333;
  text-align: center;
  border-radius: 4px;
}

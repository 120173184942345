.post-card-wrapper {
  border-top: 1px solid #62667175;
  padding: 16px;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.author-info {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
}

.author-image {
  object-fit: cover;
  border-radius: 13px;
  height: 40px;
  margin-right: 10px;
}

.author-name {
  font-size: 18px;
}

.post-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.post-title {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
}

.post-image {
  object-fit: cover;
  border-radius: 12px;
  height: 40px;
  margin-right: 10px;
}

.post-details {
  font-size: 14px;
  color: #a8b3cf;
}

.main-post-image {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  height: 160px;
  margin-top: 16px;
}

.post-tags {
  display: flex;
  margin: 8px 0;
  gap: 8px;
}

.tag-tile {
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12px;
  color: #a8b3cf;
  font-weight: 500;
  border: 1px solid #a8b3cf10;
  background: #1a1f26;
}

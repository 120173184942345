.button-fill-custom {
  background-color: #212121 !important;
  color: #fff !important;
  padding: 8px 16px !important;
  border-radius: 16px;
}

.locked-screen-icon {
  font-size: 24px;
  color: #fff;
}

.locked-screen-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;

}



.locked-screen-header {
  text-align: center;
  margin-top: 40px;
}

.locked-icon {
  color: #ffffff; /* Accent color to match your theme */
}

.locked-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.locked-subtitle {
  color: #777;
  margin-top: 10px;
}

.unlock-button{
  text-decoration: none;
  text-transform: none !important;
}

.locked-screen-services {
  flex-grow: 1;
  padding-top: 20px;
}

.service-item {
  font-size: 18px;
  color: #4b4a4a;
  font-weight: 700 !important;
}
.comment-wrapper {
  padding: 16px;
  border-radius: 24px;
  border: 1px solid color-mix(in srgb, #a8b3cf, transparent 80%);
}

.comment-wrapper:hover {
  background: color-mix(in srgb, #a8b3cf, transparent 80%);
}

.user-profile {
  border-radius: 12px;
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.name {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
}

.reputation-score {
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  font-weight: 700;
}

.username-time {
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  color: #71798c;
}

.comment-text {
  font-size: 16px;
  line-height: 22px;
}

.action-icons-wrapper i {
  font-size: 16px;
  margin-right: 16px;
  font-weight: 600;
}

.achievement-card{
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.achievement-image{
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.achievement-title{
    font-size: 18px;
    font-weight: 600;
    margin-top: 8px;
}

.achievement-description{
    font-size: 14px;
    font-weight: 400;
    color: grey;
    margin-top: 8px;
}
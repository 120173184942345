.no-of-comments-text {
  font-size: 16px;
  line-height: 20px;
  color: #a8b3cf;
  margin: 16px 0;
}

.share-thoughts-container {
  padding: 0 8px;
  margin: 8px 16px;
  background: #1c1e25;
  border-radius: 16px;
  border-top: 1px solid color-mix(in srgb, #a8b3cf, transparent 80%);
}

.share-thoughts-wrapper {
  background: #000000 !important;
  z-index: 9999999;
  position: fixed !important;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px 0;
}

.share-thoughts-container span {
  font-size: 18px;
  line-height: 20px;
  padding: 12px;
  color: #a8b3cf;
}

.share-thoughts-textarea {
  background: #1a1f26;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
}

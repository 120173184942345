.authorProfileSection {
  padding-left: 20px;
  padding-right: 20px;
}

.authorImage {
  width: 120px;
  height: 120px;
  border-radius: 40px;
  object-fit: cover;
}

.authorName {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.2px;
}

.authorBio {
  display: inline;
  line-height: 1;
  font-size: 14px;
  margin-top: 6px;
  color: #9e9ab3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.socialLinks {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.topmateLinks,
.upcomingEventsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  /* overflow-x: scroll; */
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-wrap: nowrap;
}

.divider {
  border: #62667175;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  margin: 16px 0 0 0;
}
.socialLink {
  font-size: 20px;
  transition: color 0.3s ease;
}
/* Read More Section */
.bioContainer {
  display: flex;
  flex-wrap: wrap;
}

.postsHeader {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.postsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.heading-text {
  font-weight: 500;
  font-size: 18px;
}

.profile-share-bottom-sheet-sub-wrapper {
  padding: 16px 0;
  background: #3d3d3d;
  color: #ffffff;
  border-radius: 6px;
  margin-top: 16px;
}

.profile-share-bottom-sheet-sub-wrapper span {
  font-size: 16px;
  font-weight: 500;
}

.social-handles-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.social-handle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px; /* Add this line */
  min-height: 50px; /* Add this line */
}

.social-handle-share-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-handle-share-button:hover:not(:active) {
  opacity: 0.75;
}

.experience-wrapper {
  border-top: 1px solid #383636;
}
.career-journey-container {
  padding: 16px;
}

.timeline-container {
  padding: 16px;
}

.experience-container {
  padding: 16px;
}

.timeline-divider {
  position: relative;
  width: 20px; /* Adjust as needed */
}

.timeline-divider::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #383636;
}

.timeline-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #4a90e2; /* Adjust dot color */
  border-radius: 50%;
}

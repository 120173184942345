@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none; /* Disable text selection on all devices */
  -webkit-touch-callout: none; /* Disable long press callout on iOS */
}

code {
  font-family: 'Open Sans Variable', sans-serif;
}

/* Apply Open Sans globally */
* {
  font-family: 'Open Sans Variable', sans-serif;
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
}

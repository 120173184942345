.upcoming-event-page {
  background-color: #1a1a1a;
  color: #ffffff;
}
.custom-navbar {
  background-color: #1a1a1a;
}
.join-button {
  background-color: #007aff;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 15px;
  border: none;
}
.event-header,
.event-container {
  position: relative;
  background-color: #1a1a1a;
  border-radius: 26px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.event-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffffff;
  color: grey;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.event-title {
  font-size: 24px;
  margin-bottom: 15px;
}
.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.date-time {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.date,
.wrapper {
  background-color: #333333;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  /* color: rgba(27, 27, 24, 0.65); */
}
.month {
  font-size: 12px;
  display: block;
}
.day {
  font-size: 20px;
  font-weight: bold;
}
.time {
  font-size: 14px;
}
.location,
.price {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.location i,
.price i {
  margin-right: 10px;
}
.subtext {
  font-size: 12px;
  color: #999999;
  display: block;
}
.event-details {
  margin-top: 30px;
  padding: 20px;
}

.event-details > div {
  padding: 16px 0;
}
.host-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}
.host-image,
.attendee-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.event-location h3,
.attendees h3 {
  font-size: 16px;
  margin-bottom: 10px;
}
.location-item {
  display: flex;
  align-items: center;
}
.location-item i {
  margin-right: 10px;
}
.spread-word-button {
  width: fit-content !important;
  background: transparent;
  color: #ffffff;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 20px;
}

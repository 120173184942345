.quick-take-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.show-more-button {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.quick-take-card {
  padding: 16px 0;
  border-bottom: 1px dotted #e0e0e0;
}
